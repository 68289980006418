import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import { keys } from "lodash";

const buildStyles   = makeStyles(theme => ({
  root  : {
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  errorCard: {
    width: "40%",
    border: `1px solid ${theme.palette.error.dark}`, //`
    borderRadius: 5,
    // borderColor: theme.palette.error.main,
    background: theme.palette.common.white,
  },
  errorContent: {
    background: `${theme.palette.error.light}33`, //`
  },
  errorHeader: {
    backgroundColor: `${theme.palette.error.light}55`, //`
    borderBottomColor: theme.palette.error.main,
    color: theme.palette.error.dark,
    padding: `${theme.spacing(1)}px ${theme.spacing(0)}px`, //`
  },
  errorBody: {
    padding: theme.spacing(2),
  },
  title: {
    fontSize: "1.2rem",
  },
  errorText: {
    textAlign: "center",
    fontSize: "0.9rem",
    fontWeight: 300,
  },
  details: {
    backgroundColor: `${theme.palette.error.light}11`, //` 
    width: "100%",
  },
  errorGroup: {
    width: "100%",
  },
  errorGroupHeader: {
    // textAlign: "left",
    fontSize: "1rem",
    fontWeight: 300,
  },
  errorGroupItem: {
    // textAlign: "center",
    marginLeft: theme.spacing(1),
    fontSize: "0.9rem",
    fontWeight: 300,
  }
}));

interface IErrorCardProps {
  message?: string;
  details?: string;
  error?: any;
}

interface IError {
  errors?: Record<string, string[]>;
  detail?: string;
  status: number;
  title: string;
  type: string;  
}

//---
//Converts the RFC Error into a Header/List with the error key (the field in a validation error)
//and the error items (the validation failures, in a validation error).
//TESTME: Test this against other RFC errors, beside the Validation Error, and with Validation Errors with multiple fields in the error
const getErrorContent = (error: IError, classes: any) => {
  if(!error) return null;
  const theError = error.errors ?? error;
  if(theError.detail){
    return (<Typography className={classes.errorGroupItem}>{theError.detail}</Typography>);
  } else if (theError.status == 404)
  {
    return (<Typography className={classes.errorGroupItem}>Unable to reach our services. Is your internet connected?</Typography>);
  }
  else{
    const validationFields = theError as Record<string, any>;
    const errorContent = keys(validationFields).map(key => {
      const value = validationFields[key];
      if (Array.isArray(value)) {
        return (
          <div key={key} className={classes.errorGroup}>
            <Typography className={classes.errorGroupHeader} key={`${key}-header`}>{key}:</Typography>
            <ol>
              {value.map((v, i) => <li className={classes.errorGroupItem} key={i}>{v}</li>)}
            </ol>
          </div>
        );
      } else {
        return (
          <div key={key} className={classes.errorGroup}>
            <Typography className={classes.errorGroupHeader} key={`${key}-header`}>{key}:</Typography>
            <Typography className={classes.errorGroupItem}>{value}</Typography>
          </div>
        );
      }
    });

    return (<>{errorContent}</>);
  }
}


const ErrorCard : React.FC<IErrorCardProps> = ({message, details, error}) => {
  if(!message && !error) return null; //one or the other is required
  const classes   = buildStyles();
  const detailsContent = details ? <Typography className={classes.errorText}>{details}</Typography> : null;
  const errorContent = error ? getErrorContent(error, classes) ?? details : details;

  return (
    <Grid id="error-view" container justify="center" className={classes.root}>
      <Grid container className={classes.errorCard}>
        <Grid container className={classes.errorContent}>
          <Grid container justify="center" className={classes.errorHeader}>
            <Typography className={classes.title}>Error</Typography>
          </Grid>
          <Grid container justify="center" className={classes.errorBody}>
            <Typography className={classes.errorText}>{error?.title ?? message}</Typography>
          </Grid>
          {(details || error) && 
            <Grid container className={classes.errorBody}>
              <Accordion variant="outlined" className={classes.details}>
                <AccordionSummary>
                  <Typography className={classes.errorText}>Error Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {errorContent ?? detailsContent}
                </AccordionDetails>
              </Accordion>          
            </Grid>        
          }
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ErrorCard;